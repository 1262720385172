 
import 'bootstrap/dist/css/bootstrap.min.css';


function Header() {
    return (
        <section id="top">
            <header className="App-header">
                <nav className="navbar navbar-expand-lg navbar-light fixed-top " id="menu">
                        <div className="container-fluid">
                                <a className="navbar-brand" href='#top' id="logoTop" rel="noreferrer"> 
                                        <img  src="imagenes/franquiciarg-logo-blanco.webp" alt="franquiciarg asesoria franquicia"></img>                                   
                                </a>
                            <button class="navbar-toggler collapsed white" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
                                <span class="navbar-toggler-icon blanco"></span>
                            </button>
                            <div className="navbar-collapse collapse" id="navbarToggler" >
                                <ul className="nav nav-pills">
                                    <li className="nav-item active">
                                        <a className="nav-link nav_link_color" href="#quienes">¿Quiénes somos?</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link nav_link_color" href="#servicios ">Servicios </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link nav_link_color" href="#franquicias ">Franquicias </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link nav_link_color" href="#contacto">Contacto</a>
                                    </li>
                                    <li className="nav-item redsocialMob">
                                        <a className="nav-link linkeicon" href="https://www.linkedin.com/company/franquiciarg/" alt="link" target="_blank" title="linkedin" rel="noreferrer">
                                            <i className="fa-brands fa-linkedin"></i>
                                        </a>
                                    </li>
                                     {/*<li className='col-6'>
                                        <a className="nav-link faceIcon" href="https://facebook.com/electricamosconi" alt="link" target="_blank" title="facebook">
                                            <i className="fa-brands fa-facebook"></i>
                                        </a>
                                    </li> */}
                                </ul>
                            </div>
                            <div className='conteLinked'>
                                <ul className="row">
                                    <li className='col-6'>
                                        <a className="nav-link linkeicon" href="https://www.linkedin.com/company/franquiciarg/" alt="link" target="_blank" title="linkedin" rel="noreferrer">
                                            <i className="fa-brands fa-linkedin"></i>
                                        </a>
                                    </li>
                                    {/*<li className='col-6'>
                                        <a className="nav-link faceIcon" href="https://facebook.com/electricamosconi" alt="link" target="_blank" title="facebook">
                                            <i className="fa-brands fa-facebook"></i>
                                        </a>
                                    </li> */}
                                </ul>
                            </div>
                        </div>  
                    </nav>
                    <div className='portada' id='portada' style={{backgroundImage: 'url(imagenes/portada.webp)',backgroundRepeat: 'no-repeat'}}>
                        <div>
                            <div className="container wow fadeInDown animated" tabIndex="0" alt="titulo">
                                <h1>¡TRANSFORMÁ TU NEGOCIO EN UNA FRANQUICIA EXITOSA!  <strong style={{position: 'absolute', color: 'transparent', top: '0px', left:'0px',fontSize:'0.01px'}}>Franquicias Rentables en argentina - franquiciar mi negocio en argentina - franquicias econ贸micas - venta de franquicias y negocios - franquiciar - invertir en franquicias - guia de franquicias - asociación de franquicias. Expertos en franquicias. Promoción de franquicias</strong> </h1>
                            </div>
                        </div>
                    
                    </div>
            </header>
        </section>
        
        

    );
  }
  
  export default Header;