import 'bootstrap/dist/css/bootstrap.min.css';

import React, { useState, useRef  } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const CONTACTO = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    whatsapp: '',
    empresa: '',
    message: '',
  });
  const [captcha, setCaptcha] = useState(null);
  const [errors, setErrors] = useState({});

   // Referencia para el reCAPTCHA
   const recaptchaRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target; 
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validate = () => {
    let formErrors = {};
    if (!formData.name) {
      formErrors.name = 'Debe ingresar su Nombre';
    } else if (formData.name.length < 3){
      formErrors.name = 'Debe ingresar al menos 3 caracteres';
    } else if(formData.name.length > 30){
      formErrors.name = 'Máximo 30 caracteres';
    }

    if (!formData.email) {
      formErrors.email = 'Debe ingresar su Email';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = 'La dirección de Email es invalida';
    }

    if (!formData.whatsapp) {
      formErrors.whatsapp = 'Debe ingresar un número';
    } else if (formData.whatsapp.length < 10) {//(wassapp.value.length < 10 || wassapp.value.length >= 11)
      formErrors.whatsapp = 'Debe ingresar 10 dígitos';
    } else if (formData.whatsapp.length > 10) {//(wassapp.value.length < 10 || wassapp.value.length >= 11)
      formErrors.whatsapp = 'Máximo 10 dígitos';
    }

    if (!formData.empresa) {
      formErrors.empresa = 'Debe ingresar una Marca';
    } else if (formData.empresa.length < 3){
      formErrors.empresa = 'Debe ingresar al menos 3 caracteres';
    } else if (formData.empresa.length > 40){
      formErrors.empresa = 'Máximo 40 caracteres';
    }

    if (!formData.message) {
      formErrors.message = 'Debe escribir un mensaje';
    } else if (formData.message.length < 20){
      formErrors.message = 'Debe ingresar mínimo 20 caracteres';
    }

    if (!captcha) formErrors.captcha = 'El captcha es obligatorio';
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  /*const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      // Handle form submission (e.g., send data to a server)
      console.log('El formulario se envió con éxito:', formData);
      // Reset form fields
      setFormData({
        name: '',
        email: '',
        whatsapp: '',
        empresa: '',
        message: '',
      });
      setCaptcha(null);
    }
  };*/
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const enviaForm = document.getElementById('enviaForm');
      enviaForm.innerHTML = ' <i class="fas fa-spinner fa-pulse"></i> '; 

      try {
        const response = await fetch('https://www.franquiciarg.com.ar/php/send_mail.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams({
            ...formData,
            'g-recaptcha-response': captcha
          }).toString(),  
        });
  
        if (response.ok) {
          const enviaFormdOS = document.getElementById('enviaForm');
          enviaFormdOS.innerHTML = '  '; 
          console.log('El formulario se envió con éxito:', formData);
          alert('¡Gracias! Tu mensaje ha sido enviado con éxito.');   

          // Reset form fields
          setFormData({
            name: '',
            email: '',
            whatsapp: '',
            empresa: '',
            message: '',
            enviaFormdOS: ' ',
          });
          setCaptcha(null);

          // Reset reCAPTCHA
          recaptchaRef.current.reset();
        } else {
          alert('Oops! Algo salió mal y no pudimos enviar tu mensaje.');
        }
      } catch (error) {
        alert('Error en la conexión. Intenta de nuevo más tarde.');
      }
    }
  };

  const handleCaptchaChange = (value) => {
    setCaptcha(value);
  };

  return (
    <section className="seccion_cuatro" id="contacto">

      <div className="row">
        <div className="col-9">
          <div className="conteTitu  wow fadeInDown animated">
            <h4 className="title" tabIndex="0">Contactate</h4>
            <hr></hr>
          </div>
          <div className="contForm  wow fadeInLeft animated">
            <form onSubmit={handleSubmit}>
              <div>
                <h4>Completá el formulario y nos contactaremos a la brevedad</h4>
              </div>
              <div className="row">
                <div className="col-6">
                  <div>
                    <label htmlFor="name">  Nombre y apellido</label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Escriba su nombre completo"
                      value={formData.name}
                      onChange={handleChange}
                    />
                    {errors.name && <p className="error">{errors.name}</p>}
                  </div>


                  <div>
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="correo@mail.com"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {errors.email && <p className="error">{errors.email}</p>}
                  </div>

                </div>

                <div className="col-6">
                  <div>
                    <label htmlFor="whatsapp ">N° Whatsapp (sin '+,54,9,0')</label>
                    <input
                      type="number"
                      id="whatsapp"
                      name="whatsapp"
                      placeholder="Ej.: 11 6666 2222"
                      pattern="\d{10}"
                      minLength="10"
                      maxLength="10" 
                      value={formData.whatsapp}
                      onChange={handleChange}
                    />
                    {errors.whatsapp && <p className="error">{errors.whatsapp}</p>}
                  </div>

                  <div>
                    <label htmlFor="empresa">Marca</label>
                    <input
                      type="text"
                      id="empresa"
                      name="empresa"
                      placeholder="Nombre de la Marca de su interés"
                      value={formData.empresa}
                      onChange={handleChange}
                    />
                    {errors.empresa && <p className="error">{errors.empresa}</p>}
                  </div>

                </div>
              </div>
              <div className="contTxtArea">
                <label htmlFor="message">Message</label>
                <textarea
                  id="message"
                  name="message"
                  placeholder="Escriba su mensaje"
                  value={formData.message}
                  onChange={handleChange}
                ></textarea>
                {errors.message && <p className="error">{errors.message}</p>}
              </div>

              <div className="recapcha">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey="6Ld2TywqAAAAAP8VyRN8KUFx08fRepOrJpGv6yNF"
                  onChange={handleCaptchaChange}
                />
                {errors.captcha && <p className="error">{errors.captcha}</p>}
              </div>
              <button type="submit">Enviar</button>
              <div id="enviaForm"> </div>
            </form>

          </div>

        </div>  
        <div className="col-3  wow fadeInRight animated">
          <div>
            <h4 tabIndex="0">Medios de contacto</h4>
          </div>
          <div>
            <a href="https://api.whatsapp.com/send?phone=+5491134635951&amp;text= ¡Hola! Me pongo en contacto con Uds. porque  " title="Enviar whatsApp" target="_blank" rel="noreferrer">  <p><i class="fa-brands fa-whatsapp"></i> +54 9 11 3463 5951</p></a>
            <a href="mailto: info@franquiciarg.com.ar" title="Enviar mail"><p><i className="fa-solid fa-envelope"></i>  info@franquiciarg.com.ar </p></a>
          </div>
        </div>
      </div>

    </section>
  );
};

export default CONTACTO;


